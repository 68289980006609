import {
  APICallOptions,
  SplunkCompleteLogger,
  SplunkErrorLogger,
  SplunkInfoLogger,
  SplunkServiceConfig,
  SplunkStartLogger,
} from '@common/interfaces';
import SplunkLogger from './splunk.service';
import axios, { AxiosError } from 'axios';
import { LocalStorageUtils } from '@common/utilities';

interface CustomResponse {
  isSuccess: boolean;
  data: any;
  statusCode?: number;
}

export default class BaseService {
  static HMPC_API_BACKEND: string | any =
    process.env.REACT_APP_HMPC_API_BASEPATH + process.env.REACT_APP_HMPC_API_BACKEND;

  async fetchHandler(
    url: string,
    options: APICallOptions,
    splunkData: SplunkServiceConfig
  ): Promise<CustomResponse> {
    const startObj: SplunkStartLogger = {
      ...splunkData,
      startTime: new Date().getTime(),
    };
    SplunkLogger.start(startObj);
    try {
      const body = options.body;
      options.headers.mode = options.mode;
      options.headers.vin = LocalStorageUtils.getVinNumber();
      options.headers.env = process.env.REACT_APP_HMPC_ENV;

      const response = await axios.post(BaseService.HMPC_API_BACKEND, body, options);

      const data = response.data;
      const statusCode = response.status;
      const completetObj: SplunkCompleteLogger = {
        ...splunkData,
        statusCode: statusCode.toString(),
        statusDesc: JSON.stringify(data).substr(0, 250),
        completeTime: new Date().getTime(),
      };
      SplunkLogger.complete(completetObj);
      return { isSuccess: true, data, statusCode };
    } catch (error) {
      const typedError = error as AxiosError;
      if (error instanceof Response) {
        const errorResponse = await error.json();
        this.splunkError(splunkData, error.statusText, error.status.toString());
        return { isSuccess: false, data: errorResponse, statusCode: error.status };
      }

      if (error instanceof AxiosError && error.code === 'ECONNABORTED') {
        const timeOutObj: SplunkErrorLogger = {
          ...splunkData,
          error: `Aborting the axios call in ${process.env.REACT_APP_HMPC_NEAR_BY_CHARGERS_TIMEOUT_SETTING} sec ${error.code} status code: ${error.status}`,
          completeTime: new Date().getTime(),
          statusCode: error.status?.toString() ?? '408',
        };
        SplunkLogger.error(timeOutObj);
        return { isSuccess: false, data: error.message, statusCode: 408 };
      }

      let statusDescValue;
      if (typedError?.response?.data) {
        const errorRes = JSON.stringify(typedError?.response?.data);
        const errorResJSON = JSON.parse(errorRes);
        statusDescValue =
          'messages' in errorResJSON.error ? errorResJSON.error.messages['0'] : null;
        return { isSuccess: false, data: typedError, statusCode: typedError?.response?.status };
      }
      this.splunkError(splunkData, statusDescValue, typedError?.message);
      return { isSuccess: false, data: 'Something Went Wrong' };
    }
  }

  splunkError(splunkData: SplunkServiceConfig, error: string, statusCode: string): void {
    const errorObj: SplunkErrorLogger = {
      ...splunkData,
      error,
      statusCode,
      completeTime: new Date().getTime(),
    };
    SplunkLogger.error(errorObj);
  }

  splunkInfo(splunkData: SplunkServiceConfig, statusDesc: string): void {
    const infoObj: SplunkInfoLogger = {
      ...splunkData,
      statusDesc,
      logTime: new Date().getTime(),
    };
    SplunkLogger.log(infoObj);
  }
}
