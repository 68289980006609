import { v4 as uuidv4 } from 'uuid';
import { AmplitudeObj } from '@common/interfaces/amplitudeData.interface';
import { AMPLITUDE_CONSTANTS } from '@common/enums';
import ParkingObjectFilter from '@common/utilities/ParkingObjectFilterConversion';
import AmplitudeService from '@common/services/amplitude.service';
import { convertToFeet } from './filterUtils';
import { FilterAPIOptions } from '@common/interfaces';

export const getOverHeadClearance = (
  clearance: number | undefined,
  preferredDistanceUnit: string
): string => {
  if (clearance) {
    const clearanceValue = convertToFeet(clearance, 'km', preferredDistanceUnit);
    return `${clearanceValue} ${preferredDistanceUnit === 'km' ? 'meters' : 'feet'}`;
  }
  return 'N/A';
};

export function getAmplitudeObj(
  reqPayload: FilterAPIOptions,
  isLogAmplitude: boolean,
  preferredDistanceUnit: string,
  amplitudeService: AmplitudeService
): void {
  const newTraceID: string = uuidv4();
  if (isLogAmplitude) {
    const amplitudeObj: AmplitudeObj = {
      eventType: AMPLITUDE_CONSTANTS.FILTERS_APPLIED,
      screenName: AMPLITUDE_CONSTANTS.FILTER_MODAL,
      traceID: newTraceID,
      chargeType:
        reqPayload && reqPayload.evsePowerLevel ? reqPayload.evsePowerLevel.join(', ') : 'N/A',
      isInNetwork: !!reqPayload.networksAccepted,
      isAvailable: !!reqPayload.evseAvailability,
      isFree: !!reqPayload.freeChargeOnly,
      isNetworkName: !!reqPayload.networkId,
      isPlugNCharge: !!reqPayload.plugNChargeOnly,
      isRestrictedAccess: reqPayload.accessTypeId?.length === [-2, -1, 1].length ? false : true,
      minimumRating: reqPayload.minimumScore ? reqPayload.minimumScore / 2 : 'N/A',
      clearance: getOverHeadClearance(reqPayload.overHeadClearance, preferredDistanceUnit),
      trailerAccess: reqPayload.parkingObjectFilter
        ? ParkingObjectFilter.filterTextsForAmplitude(reqPayload.parkingObjectFilter)
        : [],
    };
    amplitudeService?.postAmplitudeData(amplitudeObj);
  }
}
