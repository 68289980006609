import { NUMBER } from '@common/enums';

// Convert meters to km or mi
export function convertMetersTo(to: string, meters: number): string {
  if (to === 'km') {
    return (meters / 1000).toFixed(1);
  }
  return (meters * NUMBER.METERS_TO_MILES_CONSTANT).toFixed(1);
}

export function convertDistanceFromTo(
  from: string,
  to: string,
  amount: number | string | null
): string {
  if (typeof amount === 'undefined' || amount === null) {
    return '--';
  }
  const amountNum = Number(amount);
  if (isNaN(amountNum)) {
    return '--';
  }
  if (to === from) {
    return Math.ceil(amountNum).toString();
  } else if (from === 'mi' && to === 'km') {
    return Math.ceil(amountNum * (1 / NUMBER.MILES_TO_KILOMETERS_CONSTANT)).toString();
  } else if (from === 'km' && to === 'mi') {
    return Math.ceil(amountNum * NUMBER.KILOMETERS_TO_MILES_CONSTANT).toString();
  } else {
    return 'N/A';
  }
}

// Compare keys by descending order
export function isDCFastCharger(connectorType: string | null): boolean {
  return connectorType === 'DC CCS';
}

export function isMin80Percent(percentVal: string): boolean {
  const percentValToNum: number = parseInt(percentVal);

  return percentValToNum >= 80;
}

export function getRangeCeiled(
  currentBatteryRange: string,
  startingBatteryRange: string,
  to: string
): number {
  const convertedCurrentRange = parseFloat(currentBatteryRange);
  if (isNaN(convertedCurrentRange)) {
    return 0;
  }
  const convertedStartRange = parseFloat(startingBatteryRange);
  if (isNaN(convertedStartRange)) {
    return 0;
  }
  if (to === 'km') {
    return Math.max(0, Math.ceil(convertedCurrentRange) - Math.ceil(convertedStartRange));
  }
  if (to === 'mi') {
    return Math.max(
      0,
      Math.ceil(convertedCurrentRange * NUMBER.KILOMETERS_TO_MILES_CONSTANT) -
        Math.ceil(convertedStartRange * NUMBER.KILOMETERS_TO_MILES_CONSTANT)
    );
  }
  return 0;
}
