import { FILTER_TYPE_CONTENT_KEY } from '@common/enums';
import { FilterOption } from '@common/interfaces';

export function getTotalSelectedFilters(filters: FilterOption[]): number {
  let selectedFilters: FilterOption[] = [];
  filters.forEach((filter: FilterOption) => {
    if (filter.isSelected) {
      //only include the trailer access sub-options if trailer access is on
      if (
        filter.group === 'trailerAccess' &&
        filter.type !== FILTER_TYPE_CONTENT_KEY.TRAILER_ACCESS
      ) {
        if (!selectedFilters.find((x) => x.type === FILTER_TYPE_CONTENT_KEY.TRAILER_ACCESS)) {
          return;
        }
      }

      if (filter.type === FILTER_TYPE_CONTENT_KEY.MIN_RATING && !filter.value) {
        return;
      }
      selectedFilters.push(filter);
    }
  });

  //dont count the trailer access filter, only its sub-options
  selectedFilters = selectedFilters.filter(
    (filter) => filter.type !== FILTER_TYPE_CONTENT_KEY.TRAILER_ACCESS
  );

  const uniqueFilterSelected: string[] = [
    ...new Set(
      selectedFilters.map((selectedFilter) => {
        if (!!selectedFilter.group) return selectedFilter.group;
        else if (!!selectedFilter.category) return selectedFilter.category;
        else return selectedFilter.type;
      })
    ),
  ];

  return uniqueFilterSelected.length;
}

export function convertToFeet(val: number, fromUnit: string, toUnit: string): number {
  if (fromUnit === 'km' && toUnit === 'mi') {
    if (val >= 5) {
      return Math.ceil(5 * 3.28084 * 10) / 10;
    }
    if (val <= 2) {
      return Math.floor(2 * 3.28084 * 10) / 10;
    }
    return parseFloat((val * 3.28084).toFixed(1));
  }
  if (fromUnit === 'mi' && toUnit === 'km') {
    if (val >= 16.5) {
      return Math.floor((val / 3.28084) * 10) / 10;
    }
    if (val === 6.5) {
      return Math.ceil((val / 3.28084) * 10) / 10;
    }
    return parseFloat((val / 3.28084).toFixed(1));
  }
  return parseFloat(val.toFixed(1));
}
