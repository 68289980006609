import {
  API_CACHE_TYPE,
  API_CONTENT_TYPE,
  API_CREDENTIALS,
  API_MODE,
  API_METHODS,
} from '@common/enums';
import { APICallOptions, SplunkServiceConfig } from '@common/interfaces';
import BaseService from './baseService';

export default class ConfigDataService extends BaseService {
  /**
   * Gets Subscription from CVPOD API.
   * @param {}
   * @returns {Promise<any>}
   */
  static API_CONFIG_URL: string | any = '/config';
  async getConfigData(traceID: string | undefined): Promise<any> {
    const body: any = {};
    const options: APICallOptions = {
      method: API_METHODS.POST,
      mode: API_MODE.CORS,
      cache: API_CACHE_TYPE.NO_CACHE,
      credentials: API_CREDENTIALS.SAME_ORIGIN,
      headers: {
        Accept: API_CONTENT_TYPE.JSON,
        'Content-Type': API_CONTENT_TYPE.JSON,
        'x-b3-traceId': traceID,
        'x-b3-spanId': traceID,
        'x-correlation-id': traceID,
        url: ConfigDataService.API_CONFIG_URL,
      },
      body: JSON.stringify(body),
    };
    const splunkServiceConfig: SplunkServiceConfig = {
      service: 'ConfigDataService',
      functionName: 'getConfigData',
      traceID,
      correlationID: traceID,
    };
    try {
      const { isSuccess, data } = await this.fetchHandler(
        ConfigDataService.API_CONFIG_URL,
        options,
        splunkServiceConfig
      );
      if (isSuccess) {
        return data;
      } else {
        this.splunkInfo(
          splunkServiceConfig,
          'Received empty response from getConfigData service. '
        );
        return true;
      }
    } catch (error) {
      const typedError = error as Error;
      this.splunkError(splunkServiceConfig, typedError?.message, '');
      return true;
    }
  }
}
