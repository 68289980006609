import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { LocalStorageUtils } from '@common/utilities';
import { charge_assist_reatil_logo, info_icon_reatil } from '@common/assets/images';
import { AMPLITUDE_CONSTANTS } from '@common/enums/amplitude.enum';
import { AmplitudeObj } from '@common/interfaces/amplitudeData.interface';
import AmplitudeService from '@common/services/amplitude.service';
import { RetailHelpModal } from '../modals/retailHelpModal/retailHelpModal';
import getFlagValue from '@common/utilities/getFeatureFlagValue';

export interface RetailHeaderProps {
  t: (arg0: string) => string;
  vehiclePluggedIn: boolean;
  vehicleIsCharging: boolean | null;
  history: any;
  isDriverRestrictionsActive: boolean;
  openDriverRestrictionsModal: () => void;
}

export interface RetailHeaderState {
  isHelpModalOpen: boolean;
}

class RetailHeader extends Component<RetailHeaderProps, RetailHeaderState> {
  readonly amplitudeService: AmplitudeService;

  constructor(props: RetailHeaderProps) {
    super(props);

    this.state = {
      isHelpModalOpen: false,
    };
    this.amplitudeService = new AmplitudeService();
  }

  closeHelpModal = (reload: boolean): void => {
    this.setState({
      isHelpModalOpen: false,
    });
    if (reload) {
      this.cacheClearRetail();
    }
  };

  cacheClearRetail = () => {
    LocalStorageUtils.clearLocalStorage();
    window.caches.keys().then((keys: any) => {
      return Promise.all(
        keys.map((k: string) => {
          window.caches.delete(k);
        })
      );
    });
    if (window.location.pathname === '/hmpcChargingLocations') {
      this.props.history.push('/');
    }
    window.location.reload();
  };

  openHelpModalRetail = (): void => {
    const isDriverDistraction = getFlagValue('driver-distraction');
    if (this.props.isDriverRestrictionsActive && isDriverDistraction) {
      this.props.openDriverRestrictionsModal();
    } else {
      this.setState({
        isHelpModalOpen: true,
      });
    }
    const amplitudeObj: AmplitudeObj = {
      eventType: AMPLITUDE_CONSTANTS.HELP_ICON_TAPPED,
      screenName: AMPLITUDE_CONSTANTS.HEADER,
      isPluggedIn: this.props.vehiclePluggedIn,
    };
    this.amplitudeService.postAmplitudeData(amplitudeObj);
  };

  render(): React.ReactNode {
    const { t } = this.props;
    return (
      <>
        {this.state.isHelpModalOpen && (
          <RetailHelpModal
            t={t}
            isOpen={this.state.isHelpModalOpen}
            closeModal={this.closeHelpModal}
            vehicleIsCharging={this.props.vehicleIsCharging}
          />
        )}

        <div className="ca-retail-header" id="ca-retail-header">
          <div className="ca-retail-title">
            <img
              src={charge_assist_reatil_logo}
              alt="charge-assist-logo"
              data-id="retail-charge-assist-logo"
            />
            <span>{t('application_name')}</span>
            <img
              src={info_icon_reatil}
              alt="info-icon"
              onClick={this.openHelpModalRetail}
              data-id="retail-help-icon"
            />
          </div>
          <div className="ca-retail-title ca-retail-subtext">
            <span></span>
            <span>{t('search_and_activate_in_network_public_chargers')}</span>
            <span></span>
          </div>
        </div>
      </>
    );
  }
}

export default withNamespaces('translations')(RetailHeader as any);
export { RetailHeader }; //(only for testing purpose).
