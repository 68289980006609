import React, { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { withNamespaces } from 'react-i18next';
import { ConnectorData } from '@common/interfaces';
import { LocalStorageUtils } from '@common/utilities';
import ActivatingButton from '../activatingButton/activatingButton';
import PlugInButton from '../plugInButton/plugInButton';
import StopButton from '../stopButton/stopButton';
import UnPlugButton from '../unPlugButton/unPlugButton';

export interface InNetworkLinkProps {
  t: (arg0: string) => string;
  connectorsData: ConnectorData;
  connectorId: number | null;
  connectorEvseID: string | null;
  vehicleIsCharging: boolean | null;
  onClickCallback: (connectorsData: ConnectorData, connectorId: number | null) => void;
  ActivatingStatusConfigs: Map<string, boolean>;
  ConnectorIdentifierConfigs: Map<string, string>;
  isSubscriptionValid: boolean;
  openBOCNModal: () => void;
  isDriverRestrictionsActive: boolean;
  openDriverRestrictionsModal: () => void;
}

export function chargeCardOnly(authorizationMethods: string[] | null): boolean {
  if (authorizationMethods == null || authorizationMethods.length !== 1) {
    return false;
  }
  const method = authorizationMethods[0].substr(0, 4);
  return method.toUpperCase().includes('RFID');
}

export function connectorInUse(
  vehicleIsCharging: boolean | null,
  connectorEvseID: string | null
): boolean {
  const connectorObj = JSON.parse(LocalStorageUtils.getActivatedConnectorInfo()) || {};
  const evseIDValue = connectorObj.evseId;
  return !!vehicleIsCharging && evseIDValue && connectorEvseID === evseIDValue.toString();
}

function InNetworkLink(props: InNetworkLinkProps): ReactElement {
  const { t } = props;

  function isInActiveCharger() {
    const connectorIdentifierConfigs = props.vehicleIsCharging
      ? JSON.parse(LocalStorageUtils.getConnectorIdentifierConfigs()) || {}
      : props.ConnectorIdentifierConfigs;
    const connectorObj = props.vehicleIsCharging
      ? JSON.parse(LocalStorageUtils.getConnectorInfo()) || {}
      : props.connectorsData;
    return (
      (props.ActivatingStatusConfigs['activating'] ||
        props.ActivatingStatusConfigs['plugIn'] ||
        props.ActivatingStatusConfigs['stopActivation'] ||
        props.ActivatingStatusConfigs['unPlug'] ||
        props.vehicleIsCharging) &&
      connectorObj.evseId != connectorIdentifierConfigs.evseId &&
      (connectorObj.connectorId
        ? connectorObj.connectorId
        : uuidv4() != connectorIdentifierConfigs.connectorId)
    );
  }

  function shouldBeDisabled() {
    return !props.isSubscriptionValid && !props.vehicleIsCharging;
  }

  function isActivatedConnectorDisabled(status) {
    switch (status) {
      case '0':
      case '1':
      case '2':
      case '6':
      case '7':
        return false;
      default:
        return true;
    }
  }

  function getLinkClass(status: string | null | undefined): string {
    const isSubscriptionValidInvalid = shouldBeDisabled();
    if (
      (props.ActivatingStatusConfigs['plugIn'] || props.ActivatingStatusConfigs['unPlug']) &&
      isActivated()
    ) {
      return 'text-blue select-button-plug-in';
    }
    if (
      (isActivatedConnectorDisabled(status) || isSubscriptionValidInvalid || isInActiveCharger()) &&
      !isActivated()
    ) {
      return `text-disabled select-button-disabled ${
        isSubscriptionValidInvalid ? 'pointer-event' : ''
      }`;
    }
    return `text-blue select-button ${
      props.isDriverRestrictionsActive ? 'select-button-disabled pointer-event' : ''
    }`;
  }

  function isActivated() {
    const connectorIdentifierConfigs = props.vehicleIsCharging
      ? JSON.parse(LocalStorageUtils.getConnectorIdentifierConfigs()) || {}
      : props.ConnectorIdentifierConfigs;
    const connectorObj = props.connectorsData;
    return (
      connectorObj.evseId === connectorIdentifierConfigs.evseId &&
      connectorObj.connectorId === connectorIdentifierConfigs.connectorId
    );
  }

  function renderButton(): ReactElement | string {
    const activatingStatusConfigs = props.vehicleIsCharging
      ? JSON.parse(LocalStorageUtils.getActivatingStatusConfigs()) || {}
      : props.ActivatingStatusConfigs;

    if (isActivated()) {
      if (activatingStatusConfigs.activating) {
        return <ActivatingButton />;
      } else if (activatingStatusConfigs.plugIn) {
        return <PlugInButton />;
      } else if (activatingStatusConfigs.stopActivation) {
        return <StopButton />;
      } else if (activatingStatusConfigs.unPlug) {
        return <UnPlugButton />;
      }
    }
    return t('activate');
  }

  function onActivateClick() {
    if (props.isDriverRestrictionsActive) {
      props.openDriverRestrictionsModal();
      return;
    }
    if (shouldBeDisabled()) {
      props.openBOCNModal();
      return;
    }
    props.onClickCallback(props.connectorsData, props.connectorId);
  }

  const ChargerTag = isActivated() ? 'div' : 'button';

  if (chargeCardOnly(props.connectorsData.authorizationMethods)) {
    return (
      <ChargerTag data-id="in-network-link" className="text-disabled">
        {t('charge_card_only')}
      </ChargerTag>
    );
  }

  return (
    <ChargerTag
      data-id="in-network-link"
      onClick={onActivateClick}
      className={getLinkClass(props.connectorsData.connectorStatus)}>
      {renderButton()}
    </ChargerTag>
  );
}

export default withNamespaces('translations')(InNetworkLink as any);
export { InNetworkLink };
