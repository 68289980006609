import {
  API_CACHE_TYPE,
  API_CONTENT_TYPE,
  API_CREDENTIALS,
  API_MODE,
  API_METHODS,
} from '@common/enums';
import { APICallOptions, SplunkServiceConfig } from '@common/interfaces';
import BaseService from './baseService';

export default class CvPodService extends BaseService {
  /**
   * Gets Subscription from CVPOD API.
   * @param {}
   * @returns {Promise<any>}
   */
  static CVPOD_API_URL: string | any = process.env.REACT_APP_HMPC_API_CVPOD_API;
  async getSubscription(traceID: string | undefined): Promise<any> {
    const body: any = {};
    const options: APICallOptions = {
      method: API_METHODS.POST,
      mode: API_MODE.CORS,
      cache: API_CACHE_TYPE.NO_CACHE,
      credentials: API_CREDENTIALS.SAME_ORIGIN,
      headers: {
        Accept: API_CONTENT_TYPE.JSON,
        'Content-Type': API_CONTENT_TYPE.JSON,
        'x-b3-traceId': traceID,
        'x-b3-spanId': traceID,
        'x-correlation-id': traceID,
        url: CvPodService.CVPOD_API_URL,
      },
      body: JSON.stringify(body),
    };
    const splunkServiceConfig: SplunkServiceConfig = {
      service: 'CvPodService',
      functionName: 'getSubscription',
      traceID,
      correlationID: traceID,
    };
    try {
      const { isSuccess, data } = await this.fetchHandler(
        CvPodService.CVPOD_API_URL,
        options,
        splunkServiceConfig
      );
      if (isSuccess) {
        return data.result;
      } else {
        this.splunkInfo(splunkServiceConfig, 'Received empty response for CvPod Api');
        return true;
      }
    } catch (error) {
      const typedError = error as Error;
      this.splunkError(splunkServiceConfig, typedError?.message, '');
      return true;
    }
  }
}
